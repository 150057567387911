import AdminLayout from "./layouts/AdminLayout";

import Home from "./routes/home";
import AddAccount from "./routes/accounts";
import ApplyIPO from "./routes/apply_ipo";
import About from "./routes/about";

import { Route, Routes } from "react-router-dom";

import { Toaster } from "react-hot-toast";
import Maintainence from "./routes/maintainence";

function App() {
  return (
    <>
      <Toaster reverseOrder={false} />
      <AdminLayout>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route exact path="accounts" element={<AddAccount />} />
            <Route exact path="apply" element={<ApplyIPO />} />
            <Route exact path="about" element={<About />} />
          </Route>
          <Route index element={<Maintainence />} />
        </Routes>
      </AdminLayout>
    </>
  );
}

export default App;
