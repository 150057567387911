import { Container, Stack, Heading, Text } from "@chakra-ui/react";

import { Link } from "react-router-dom";

export default function Home() {
  return (
    <Container maxW={"7xl"}>
      <Stack
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: "column", md: "row" }}
      >
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
          >
            <Text
              as={"span"}
              position={"relative"}
              _after={{
                content: "''",
                width: "full",
                height: "30%",
                position: "absolute",
                bottom: 1,
                left: 0,
                bg: "red.400",
                zIndex: -1,
              }}
            >
              Add Once,
            </Text>
            <br />
            <Text as={"span"} color={"green.500"}>
              Apply always with single click
            </Text>
          </Heading>
          <Text fontSize={"lg"} color="black">
            You can apply for any IPO by adding your account details at a single
            click. First Navigate to <Link to="/accounts">Accounts page</Link>{" "}
            and add your account details. You can add any number of accounts.
            Then navigate to Apply IPO page and apply for any IPO which is
            currently issued for application.
          </Text>
        </Stack>
      </Stack>
    </Container>
  );
}
