import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  useColorModeValue,
  Heading,
  Icon,
  Text,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import toast from "react-hot-toast";

import React, { useState } from "react";

// Icons
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { MdSave } from "react-icons/md";

// helpers
import { bulkshareLogin } from "../helpers/bulkShareHelper";
import { editUserDetails } from "../helpers/storageHelper";

const EditAccount = ({ userData, modalcloser }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const submit = (e) => {
    setIsLoggingIn(true);
    e.preventDefault();
    let username = userData.username;
    let password = e.target.password.value;
    let capital = userData.clientId;

    let pin = e.target.pin.value;
    if (pin.length !== 4) {
      toast.error("Pin Must be 4 Digit.");
      setIsLoggingIn(false);
      return;
    }
    function isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    }
    if (!isNumeric(pin)) {
      toast.error("Pin Must be Numeric");
      setIsLoggingIn(false);
      return;
    }
    if (parseInt(pin) < 0) {
      toast.error("Pin Must be Greater than zero");
      setIsLoggingIn(false);
      return;
    }
    let crn = e.target.crn.value;

    bulkshareLogin(capital, username, password, pin, crn).then((res) => {
      if (res.message && res.message.includes("Attempts")) {
        toast.error("Invalid credentials");
        setIsLoggingIn(false);
        return;
      }
      toast.success("Successfully Edited!");
      editUserDetails(username, pin, crn, password);

      setIsLoggingIn(false);
      modalcloser();
    });
  };

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"}>
      <Stack spacing={8} mx={"auto"} w="full" maxW={"lg"} py={12} px={6}>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Heading>
            <Text
              fontSize={"5xl"}
              fontWeight={"bold"}
              justify="center"
              align="center"
              py={6}
              mb={4}
              fontFamily="monospace"
              letterSpacing={3}
            >
              Edit Account
            </Text>
          </Heading>

          <Text
            fontSize={"md"}
            fontWeight={"bold"}
            border="2px solid"
            px={4}
            py={2}
            my={4}
            borderColor={"yellow.400"}
            borderRadius={"lg"}
            color={"gray.500"}
          >
            Editing {userData.personalDetails.name}'s Account
            <br />
            Username : {userData.personalDetails.username}
          </Text>
          <form onSubmit={submit}>
            <Stack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="********"
                    name="password"
                    autoComplete="off"
                  />
                  <InputRightElement h={"full"}>
                    <Button
                      variant={"ghost"}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                    >
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <HStack>
                -
                <Box>
                  <FormControl isRequired>
                    <FormLabel>CRN</FormLabel>
                    <Input name="crn" type="text" />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl isRequired>
                    <FormLabel>PIN</FormLabel>
                    <Input name="pin" type="number" />
                  </FormControl>
                </Box>
              </HStack>
              <Stack spacing={10} pt={2}>
                <Button
                  size="lg"
                  bg={"yellow.400"}
                  color={"white"}
                  _hover={{
                    bg: "yellow.500",
                  }}
                  type="submit"
                >
                  <Icon as={MdSave} mr={4} h={6} w={6} color={"white"} />
                  {isLoggingIn ? "Saving Changes...." : "Save Changes"}
                </Button>
              </Stack>

              <Stack spacing={10} pt={2}>
                <HStack>
                  <Link to="/apply">
                    <Button
                      loadingText="Submitting"
                      size="lg"
                      bg={"transparent"}
                      color={"blue.400"}
                      _hover={{
                        bg: "blue.400",
                        color: "white",
                      }}
                      borderWidth={"2px"}
                      borderColor={"blue.400"}
                    >
                      Go to Apply IPO Page
                    </Button>
                  </Link>

                  <Button
                    size="lg"
                    bg={"transparent"}
                    color={"red.400"}
                    _hover={{
                      bg: "red.400",
                      color: "white",
                    }}
                    borderWidth={"2px"}
                    borderColor={"red.400"}
                    onClick={modalcloser}
                  >
                    Cancel
                  </Button>
                </HStack>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default EditAccount;
