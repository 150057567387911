import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { Box } from "@chakra-ui/react";
import Header from "../components/Header";

export default function AdminLayout({ children }) {
  return (
    <>
      <Sidebar>
        <Header />
        <Box m={4} minH="80vh">
          {children}
        </Box>
        {/* <Footer ml={{ base: 0, md: 0, lg: 60 }} /> */}
      </Sidebar>
    </>
  );
}
