import React from "react";

import { Box, Flex, Heading, HStack } from "@chakra-ui/react";

const Header = () => {
  return (
    <>
      <Box bg={"white"} px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"center"}>
          <HStack spacing={8} alignItems={"center"}>
            <Heading
              size="xl"
              textTransform={"uppercase"}
              fontFamily="monospace"
              fontWeight={800}
              letterSpacing={4}
            >
              BulkShare
            </Heading>
          </HStack>
        </Flex>
      </Box>
    </>
  );
};

export default Header;
