import { useState } from "react";
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Button,
} from "@chakra-ui/react";

import { AiFillHome } from "react-icons/ai";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { FaRegHandPointUp } from "react-icons/fa";
import { FcAbout } from "react-icons/fc";

import { NavLink as Link } from "react-router-dom";

const LinkItems = [
  { name: "Home", to: "/", icon: AiFillHome },
  { name: "Demat Accounts", to: "/accounts", icon: AiOutlineUsergroupAdd },
  { name: "Apply IPO", to: "/apply", icon: FaRegHandPointUp },
  { name: "About", to: "/about", icon: FcAbout },
];

function Sidebar({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "none", lg: "block" }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav
          display={{ base: "flex", md: "flex", lg: "none" }}
          onOpen={onOpen}
        />
        <Box
          ml={{ base: 0, md: 0, lg: 80 }}
          p="4"
          mb={{ base: 20, md: 20, lg: 0 }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 80 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      {/* <Box alignItems="center">BULK SHARE</Box> */}
      <Flex alignItems="center" justifyContent="space-between">
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem key={link.name} link={link.to} icon={link.icon}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, link, children, ...rest }) => {
  const [active, setActive] = useState(false);

  const ActiveStyles = active ? { color: "white", bg: "blue.500" } : null;

  return (
    <Link
      to={link}
      _focus={{ boxShadow: "none" }}
      style={({ isActive }) => (isActive ? setActive(true) : setActive(false))}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "blue.500",
          color: "white",
        }}
        fontWeight={600}
        textTransform="uppercase"
        {...rest}
        {...ActiveStyles}
        my={2}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="18"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      zIndex={999}
      ml={{ base: 0, md: 0, lg: 80 }}
      px={{ base: 4, md: 0, lg: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="space-around"
      position={"fixed"}
      bottom="0"
      width="full"
      {...rest}
    >
      <Link to="/accounts">
        <Button size="lg" variant="outline">
          Accounts
        </Button>
      </Link>
      <Link to="/">
        <IconButton
          size="lg"
          variant="outline"
          aria-label="open menu"
          icon={<AiFillHome />}
        />
      </Link>
      <Link to="/apply">
        <Button size="lg" variant="outline">
          Apply IPO
        </Button>
      </Link>
    </Flex>
  );
};
export default Sidebar;
