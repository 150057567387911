import { Box, Heading, Text, Button } from "@chakra-ui/react";

export default function Maintainence() {
  return (
    <Box textAlign="center" h={"100vh"} mt="80px" alignContent="center">
      {/* centered box */}

      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, teal.400, teal.600)"
        backgroundClip="text"
        verticalAlign="center"
      >
        F*C* Maintainence
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Annoyed?
      </Text>
      <Text color={"gray.500"} mb={6}>
        Sorry for the inconvenience. We are working on it.
      </Text>

      <Button
        colorScheme="teal"
        bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
        color="white"
        variant="solid"
      >
        It's Okay
      </Button>
    </Box>
  );
}
