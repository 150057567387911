// 1. Import the extendTheme function
import { extendTheme, theme as chakraTheme } from "@chakra-ui/react";

const fonts = {
  ...chakraTheme.fonts,
  body: "Poppins, sans-serif",
  heading: "Poppins, sans-serif",
};

const sizes = {
  ...chakraTheme.space,
  mobbox: "100vh" - 20,
};

// 2. Extend the theme to include custom colors, fonts, etc
const themeOverrides = {
  ...chakraTheme,
  fonts,
  sizes,
};

const customTheme = extendTheme({ themeOverrides });

export default customTheme;
