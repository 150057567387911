import React, { useRef, useState } from "react";

import {
  Heading,
  Box,
  Center,
  Text,
  Stack,
  Button,
  useColorModeValue,
  HStack,
  Icon,
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";

// import { MdOutlineAccountBox } from "react-icons/md";
import { RiAccountPinBoxFill } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import { BiEdit } from "react-icons/bi";

// helpers

import { deleteAccount } from "../helpers/storageHelper";
// import { bulkshareLogin } from "../helpers/bulkShareHelper";
import EditAccount from "./EditAccount";
import toast from "react-hot-toast";

const AccountCard = ({ userData, refetchAccounts }) => {
  const cancelRef = useRef();
  const [openAlert, setOpenAlert] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toggleDeleteAlert = (e) => {
    e.preventDefault();
    setOpenAlert(!openAlert);
  };

  const removeAccount = (e) => {
    e.preventDefault();
    deleteAccount(userData.username);
    refetchAccounts();
    toast.success("Account deleted successfully");
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="transparent" border="none" shadow="none">
          <EditAccount userData={userData} modalcloser={onClose} />
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={openAlert}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Selected Account
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={toggleDeleteAlert}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={removeAccount} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Center>
        <Box
          display={"flex"}
          bg={useColorModeValue("white", "gray.800")}
          boxShadow={"lg"}
          rounded={"md"}
          p={2}
        >
          <Box p={5}>
            <HStack
              mb={5}
              spacing={4}
              align={"left"}
              mx={2}
              justify="space-around"
            >
              <Stack>
                <Icon as={RiAccountPinBoxFill} boxSize={20} />
              </Stack>
              <Stack fontWeight={"bold"}>
                <Heading
                  fontSize={"2xl"}
                  fontFamily={"body"}
                  color={"blue.600"}
                >
                  {userData.personalDetails.name}
                </Heading>
                <HStack>
                  <Text color={"black"}>BOID: </Text>
                  <Text color={"gray.600"}>{userData.boidDetails.boid}</Text>
                </HStack>

                <HStack>
                  <Text color={"black"}>Username: </Text>
                  <Text color={"gray.600"}>
                    {userData.personalDetails.username}
                  </Text>
                </HStack>
              </Stack>
            </HStack>
            <HStack spacing={6} align={"center"} justify="center">
              <Button
                w={"full"}
                bg={"blue.500"}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
                onClick={onOpen}
              >
                <Icon as={BiEdit} mr={4} h={6} w={6} />
                Edit
              </Button>
              <Button
                w={"full"}
                bg={"red.500"}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
                onClick={toggleDeleteAlert}
              >
                <Icon as={MdDeleteOutline} mr={4} h={6} w={6} />
                Delete
              </Button>
            </HStack>
          </Box>
        </Box>
      </Center>
    </>
  );
};

export default AccountCard;
