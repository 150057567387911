import React from "react";

const About = () => {
  return (
    <div>
      React implementation of{" "}
      <a
        href="https://github.com/bipin0x01/meroshare-bot"
        target={"_blank"}
        rel="noreferrer"
      >
        {" "}
        MeroShare Bot&nbsp;
      </a>{" "}
      by&nbsp;
      <a href="https://github.com/bipin0x01" target={"_blank"} rel="noreferrer">
        Bipin.&nbsp;
      </a>
      <br />
      Improvised and Hosted by &nbsp;
      <a href="https://github.com/Parajulibkrm">@Parajulibkrm</a>
    </div>
  );
};

export default About;
