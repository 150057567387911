import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  useColorModeValue,
  Select,
  Heading,
  Icon,
  Text,
  useTimeout,
  Checkbox,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { TiPlus } from "react-icons/ti";

import { useState, useEffect } from "react";

import { addDetails, accountExists } from "../helpers/storageHelper";

import { getCapitals, bulkshareLogin } from "../helpers/bulkShareHelper";

import { toast } from "react-hot-toast";

export default function AddAccount({ modalcloser, refetchAccounts }) {
  const [showPassword, setShowPassword] = useState(false);
  const [capitals, setCapitals] = useState();
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    getCapitals().then(setCapitals);
  }, []);

  const submit = (e) => {
    e.preventDefault();
    let checkbox = e.target.checkbox;
    if (!checkbox.checked) {
      toast.error("Please accept the terms and conditions");
      return;
    }
    setIsLoggingIn(true);
    let username = e.target.username.value;
    let password = e.target.password.value;
    let capital = e.target.capital.value;
    let pin = e.target.pin.value;
    let crn = e.target.crn.value;

    if (pin.length !== 4) {
      toast.error("Pin Must be 4 Digit.");
      setIsLoggingIn(false);
      return;
    }
    function isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    }
    if (!isNumeric(pin)) {
      toast.error("Pin Must be Numeric");
      setIsLoggingIn(false);
      return;
    }
    if (parseInt(pin) < 0) {
      toast.error("Pin Must be Greater than zero");
      setIsLoggingIn(false);
      return;
    }
    if (accountExists(username)) {
      toast.error("Account already exists");
      return;
      setIsLoggingIn(false);
    }

    bulkshareLogin(capital, username, password, pin, crn).then((res) => {
      if (res.message && res.message.includes("Attempts")) {
        toast.error("Invalid credentials");
        setIsLoggingIn(false);
        return;
      }
      addDetails(res);
      toast.success("Account Login Successful! Added to the list.");
      setIsLoggingIn(false);
      refetchAccounts();
      modalcloser();
    });
  };

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"}>
      <Stack spacing={8} mx={"auto"} w="full" maxW={"lg"} py={12} px={6}>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Heading>
            <Text
              fontSize={"5xl"}
              fontWeight={"bold"}
              justify="center"
              align="center"
              py={6}
              mb={4}
              fontFamily="monospace"
              letterSpacing={3}
            >
              BulkShare
            </Text>
          </Heading>
          <form onSubmit={submit} autoComplete="off">
            <Stack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Depository Participants</FormLabel>
                <Select placeholder="Depository Participants" name="capital">
                  {capitals &&
                    capitals.map((capital, index) => (
                      <option key={index} value={capital.id}>
                        {capital.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
              <FormControl name="username" isRequired autoComplete="off">
                <FormLabel>Username</FormLabel>
                <Input
                  type="number"
                  name="username"
                  placeholder="8-Digit Username"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="********"
                    name="password"
                  />
                  <InputRightElement h={"full"}>
                    <Button
                      variant={"ghost"}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                    >
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <HStack>
                <Box>
                  <FormControl isRequired>
                    <FormLabel>CRN</FormLabel>
                    <Input name="crn" type="text" />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl isRequired>
                    <FormLabel>PIN</FormLabel>
                    <Input name="pin" type="number" />
                  </FormControl>
                </Box>
              </HStack>

              {/* short tos info */}
              <Text fontSize={"sm"} color="red.400">
                Please re-check the CRN and PIN before you submit.
              </Text>

              {/* checkbox */}
              <Checkbox size="sm" isRequired name="checkbox">
                All the information provided are saved in your browser and
                nowhere else. Agree to submit the details by checking the box.
              </Checkbox>
              <Stack spacing={10} pt={2}>
                <Button
                  loadingText="Submitting"
                  size="lg"
                  bg={"green.500"}
                  color={"white"}
                  _hover={{
                    bg: "green.600",
                  }}
                  type="submit"
                >
                  <Icon as={TiPlus} mr={4} h={6} w={6} color={"white"} />
                  {isLoggingIn ? "Adding Account...." : "Add Account"}
                </Button>
              </Stack>

              <Stack spacing={10} pt={2}>
                <HStack>
                  <Link to="/apply">
                    <Button
                      loadingText="Submitting"
                      size="lg"
                      bg={"transparent"}
                      color={"blue.400"}
                      _hover={{
                        bg: "blue.400",
                        color: "white",
                      }}
                      borderWidth={"2px"}
                      borderColor={"blue.400"}
                    >
                      Go to Apply IPO Page
                    </Button>
                  </Link>

                  <Button
                    onClick={modalcloser}
                    loadingText="Submitting"
                    size="lg"
                    bg={"transparent"}
                    color={"red.400"}
                    _hover={{
                      bg: "red.400",
                      color: "white",
                    }}
                    borderWidth={"2px"}
                    borderColor={"red.400"}
                  >
                    Cancel
                  </Button>
                </HStack>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
}
