import React, { useState, useEffect } from "react";
import {
  VStack,
  Text,
  Box,
  FormControl,
  FormLabel,
  Select,
  Button,
  Input,
  Flex,
  Heading,
  Spinner,
} from "@chakra-ui/react";

import { toast } from "react-hot-toast";

// helpers
import { fetchUserDetails } from "../../helpers/storageHelper";
import { applyIPO, getApplicableIssue } from "../../helpers/bulkShareHelper";

const ApplyIPO = () => {
  const [userAccounts] = useState(fetchUserDetails());
  const [isLoading, setIsLoading] = useState(false);
  const [availableIssue, setAvailableIssue] = useState({});

  useEffect(() => {
    setIsLoading(true);
    if (userAccounts?.length === 0) {
      setIsLoading(false);
    }
    if (userAccounts?.length > 0) {
      getApplicableIssue().then(function (data) {
        setAvailableIssue(data);
        setIsLoading(false);
      });
    }
  }, []);

  // check if an object is empty
  function isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  const apply = (e) => {
    e.preventDefault();
    let units = e.target.kitta.value;
    let issue = e.target.selectedIpo.value;
    if (!units) {
      units = 10;
    }
    applyIPO(issue, units);
  };

  return (
    <>
      <Box pt={{ base: 10, md: 10, lg: 0 }}>
        <Box mb={4}>
          <VStack align="left" lineHeight={1}>
            <Text fontSize="3xl" fontWeight={600}>
              Apply IPO
            </Text>
            <Text py={2}>Bulk IPO Apply</Text>
          </VStack>
        </Box>
        <hr />
        {/* Centered Box */}

        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width="100%"
            m="4"
            mt="16"
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size={"xl"}
            />
          </Box>
        ) : isEmpty(availableIssue) || userAccounts?.length < 1 ? (
          <Flex
            justify={{ base: "center", md: "center", lg: "center" }}
            align={{ base: "center", md: "center", lg: "center" }}
            justifyContent={{ base: "center", md: "center", lg: "center" }}
            my="20"
          >
            <Box align="center" ml={{ base: "8", md: "8", lg: "0" }} my={3}>
              <Heading
                as="h4"
                size="lg"
                color="gray.600"
                textTransform={"uppercase"}
              >
                It seems either you don't have any accounts added or there are
                no issue available to apply.
              </Heading>
            </Box>
          </Flex>
        ) : (
          <>
            <Box my={4}>
              <VStack align="left">
                <form onSubmit={apply}>
                  <FormControl isRequired mb={2}>
                    <FormLabel fontSize="2xl">
                      Choose among the following current Issues (IPOs)
                    </FormLabel>
                    <Select
                      bg="white"
                      placeholder="Select IPO"
                      name="selectedIpo"
                      id="applicableIssues"
                    >
                      {availableIssue.object &&
                        availableIssue.object.map((issue) => (
                          <option
                            key={issue.companyShareId}
                            value={issue.companyShareId}
                          >
                            {issue.companyName} ({issue.shareTypeName}:{" "}
                            {issue.shareGroupName})
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl isRequired mb={2}>
                    <FormLabel fontSize="2xl">
                      Enter the number of shares to be applied (No. of Kitta)
                    </FormLabel>
                    <Input
                      type="number"
                      bg="white"
                      placeholder="10"
                      min="10"
                      name="kitta"
                    ></Input>
                  </FormControl>
                  <Button
                    size="md"
                    my={4}
                    bg="green.500"
                    color="white"
                    border="2px solid"
                    letterSpacing={1}
                    textTransform="uppercase"
                    _hover={{
                      bg: "white",
                      color: "green",
                      borderColor: "green.600",
                    }}
                    type="submit"
                  >
                    Apply
                  </Button>
                </form>
              </VStack>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default ApplyIPO;
