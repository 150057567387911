import React, { useState, useEffect, useRef } from "react";
import AddAccount from "../../components/AddAccount";

// chakra components

import {
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  VStack,
  ModalContent,
  Box,
  Icon,
  Tooltip,
  SimpleGrid,
  Heading,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Text,
} from "@chakra-ui/react";

import { MdDangerous, MdOutlinePersonAddAlt } from "react-icons/md";
import AccountCard from "../../components/AccountCard";
import { fetchUserDetails } from "../../helpers/storageHelper";
import toast from "react-hot-toast";

const Account = () => {
  const cancelRef = useRef();
  const [openAlert, setOpenAlert] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userAccounts, setUserAccounts] = useState(fetchUserDetails());
  function refetchAccounts() {
    setUserAccounts(fetchUserDetails());
  }

  const resetAccounts = (e) => {
    e.preventDefault();
    localStorage.removeItem("credentials");
    refetchAccounts();
    setOpenAlert(false);
    toast.success("All accounts have been reset successfully");
  };

  const toggleDeleteAlert = (e) => {
    e.preventDefault();
    setOpenAlert(!openAlert);
  };
  return (
    <>
      <AlertDialog
        isOpen={openAlert}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Reset Saved Accounts
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={toggleDeleteAlert}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={resetAccounts} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Flex
        justify={{ base: "center", md: "center", lg: "left" }}
        align={{ base: "center", md: "center", lg: "left" }}
      >
        {/* button to add new account */}
        <VStack>
          <Box
            align="left"
            ml={{ base: "8", md: "8", lg: "0" }}
            display={{ base: "none", md: "none", lg: "flex" }}
            my={3}
            justifyContent="space-between"
          >
            <Button
              onClick={onOpen}
              size="lg"
              variant="outline"
              borderColor={"blue.500"}
              color={"blue.700"}
              _hover={{
                color: "white",
                bg: "blue.500",
              }}
              borderWidth="2px"
              mr={8}
            >
              Add New Account
            </Button>
            {userAccounts && userAccounts.length > 0 && (
              <Button
                // leftIcon={MdWarning}
                size="lg"
                bg={"red.500"}
                color={"white"}
                _hover={{
                  color: "red.500",
                  bg: "transparent",
                  borderColor: "red.500",
                }}
                borderWidth="2px"
                onClick={toggleDeleteAlert}
              >
                Remove all Accounts
              </Button>
            )}
          </Box>
          <Box
            position="fixed"
            bottom={24}
            borderRadius="3xl"
            left={8}
            bg={"red.500"}
            zIndex={999}
            px={5}
            py={3}
            display={{ base: "flex", md: "flex", lg: "none" }}
            onClick={toggleDeleteAlert}
          >
            <Icon
              onClick={onOpen}
              justifyContent={"center"}
              alignItems={"center"}
              as={MdDangerous}
              color={"white"}
              h={7}
              w={7}
              mr={2}
            />
            <Text fontSize="20px" textColor={"white"}>
              Reset
            </Text>
          </Box>
          <Box
            position="fixed"
            bottom={24}
            right={8}
            bg={"blue.700"}
            borderRadius="3xl"
            zIndex={999}
            px={5}
            py={3}
            display={{ base: "flex", md: "flex", lg: "none" }}
            onClick={onOpen}
          >
            <Icon
              justifyContent={"center"}
              alignItems={"center"}
              as={MdOutlinePersonAddAlt}
              color={"white"}
              h={7}
              w={7}
              mr={2}
            />
            <Text fontSize="20px" textColor={"white"}>
              Add
            </Text>
          </Box>

          {userAccounts && userAccounts.length > 0 && (
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 2, xl: 3, "2xl": 4 }}
              spacing={{ base: 4, md: 16, lg: 8, xl: 12, "2xl": 12 }}
            >
              {userAccounts &&
                userAccounts.map((account, index) => (
                  <AccountCard
                    key={index}
                    userData={account}
                    refetchAccounts={refetchAccounts}
                  />
                ))}
            </SimpleGrid>
          )}
        </VStack>

        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent bg="transparent" border="none" shadow="none">
            <AddAccount
              modalcloser={onClose}
              refetchAccounts={refetchAccounts}
            />
          </ModalContent>
        </Modal>
      </Flex>
      {userAccounts?.length < 1 ||
        (!userAccounts && (
          <Flex
            justify={{ base: "center", md: "center", lg: "center" }}
            align={{ base: "center", md: "center", lg: "center" }}
            justifyContent={{ base: "center", md: "center", lg: "center" }}
            my="20"
          >
            <Box align="center" ml={{ base: "8", md: "8", lg: "0" }} my={3}>
              <Heading
                as="h3"
                size="lg"
                color="gray.600"
                textTransform={"uppercase"}
              >
                You don't have any accounts yet. Click the add account button to
                add.
              </Heading>
            </Box>
          </Flex>
        ))}
    </>
  );
};

export default Account;
